import React from 'react';
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Sector} from "recharts";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
    piePart: {
        "&:hover": {
            fill: 'gray'
        }
    }
});

function RechartsPie(props) {
    const {data, title, height, classes} = props;
    
    const onPieSliceClick = (data, index) => {
        props.setSelectedPieSlice({index: index, type: props.pieType, data: data});
    };
    
    return (
        <div>
            {title && (<Typography variant={'h6'} align={'center'}>{title}</Typography>)}
            <ResponsiveContainer height={height}>
                <PieChart>
                    <Legend align={"center"} height={40} />
                    <Pie dataKey="value"
                         data={data.filter(x=>x.value !== 0)}
                         labelLine={false}
                         label={(...props) => renderCustomizedLabel(...props, onPieSliceClick)}
                         activeIndex={props.selectedPieSlice.type == props.pieType && props.selectedPieSlice.index !== null ? props.selectedPieSlice.index : null}
                         activeShape={renderActiveShape}
                         onClick={onPieSliceClick}
                         isAnimationActive={false}
                         onMouseLeave={() => {
                             //setActiveIndex(null);
                         }}>
                        {
                            data.map((entry, index) => <Cell className={classes.piePart} key={index}  fill={entry.color} />)
                        }
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

export default (withStyles(styles)(RechartsPie));

RechartsPie.propTypes = {
    height: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
    title: PropTypes.string
};


const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (props, onPieSliceClick) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, index, value } = props;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x  = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy  + radius * Math.sin(-midAngle * RADIAN);
    
    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" onClick={() => onPieSliceClick(props, index)}>
            {`${value}`}
        </text>
    );
};



const renderActiveShape = (props) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;

    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius+10}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
        </g>
    );
};