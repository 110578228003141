import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import React from "react";
import {useQuery} from "react-query";
import http from "../../Services/Network/http";

const queryConstants = {
    fetchStatisticsData: "fetchStatisticsData"
}
const summaryRows = [
    { rows:[
            {title: 'Lokaliteter', data: [
                    {
                        title: 'Antall lokaliteter',
                        field: 'nOfLocations'
                    },
                    {
                        title: 'Posisjoner totalt',
                        field: 'nOfFacilitiesPositions'
                    },
                    {
                        title: 'Aktive posisjoner',
                        field: 'nOfActiveFacilitiesPositions'
                    },
                    {
                        title: 'Komponenter totalt',
                        field: 'nOfFacilitiesItems'
                    },
                    {
                        title: 'Komponenter aktiv',
                        field: 'nOfActiveFacilitiesItems'
                    }
                ]},
            {title: 'Anlegg', data: [
                    {
                        title: 'Inaktive anlegg',
                        field: 'nOfPassiveFacilities'
                    },
                    {
                        title: 'Aktive anlegg',
                        field: 'nOfActiveFacilities'
                    },
                    {
                        title: 'Flåter',
                        field: 'nOfActiveBarges'
                    },
                    {
                        title: 'Antall fortøyningsliner',
                        field: 'nOfFacilitiesPositionsMooringLines'
                    },
                    {
                        title: 'Antall bur',
                        field: 'nOfCages'
                    }
                ]},
            {title: 'Brukere', data: [
                    {
                        title: 'Antall brukere',
                        field: 'nOfUsers'
                    },
                    {
                        title: 'Innlogginger siste uke',
                        field: 'nOfLoginsLast7Days'
                    },
                    {
                        title: 'Innlogginger siste måned',
                        field: 'nOfLoginsLast31Days'
                    }
                ]}
        ]},
    { rows: [
            {title: 'Dokumentasjon', data: [
                    {
                        title: 'Totalt',
                        field: 'nOfDocuments'
                    },
                    {
                        title: 'Arkiverte',
                        field: 'nOfArchivedDocuments'
                    },
                    {
                        title: 'Aktive',
                        field: 'nOfActiveDocuments'
                    },
                    {
                        title: 'Aktive lokalitetsundersøkelser',
                        field: 'nOfActiveLocationSurveyDocuments'
                    },
                    {
                        title: 'Aktive fortøyningsanalyser',
                        field: 'nOfActiveMooringAnalysisDocuments'
                    },
                    {
                        title: 'Aktive Sertifikater',
                        field: 'nOfActiveCertificateDocuments'
                    },
                    {
                        title: 'Antall Anleggssertifikater',
                        field: 'nOfActiveFacilityCertificationDocuments'
                    }
                ]},
            {title: 'Service', data: [
                    {
                        title: 'Antall servicer siste år',
                        field: 'nOfServicesLast365Days'
                    },
                    {
                        title: 'Antall servicer siste år fullført',
                        field: 'nOfCompletedServicesLast365Days'
                    },
                    {
                        title: 'Antall servicer ikke fullført siste år',
                        field: 'nOfIncompleteServicesLast365Days'
                    }
                ]},
            {title: 'Avvik', data: [
                    {
                        title: 'Totalt',
                        field: 'nOfDeviations'
                    },
                    {
                        title: 'Åpne',
                        field: 'nOfOpenDeviations'
                    },
                    {
                        title: 'Registrerte avvik siste år',
                        field: 'nOfRegisteredDeviationsLast365Days'
                    },
                    {
                        title: 'Registrerte avvik siste 3 mnd',
                        field: 'nOfRegisteredDeviationsLast90Days'
                    },
                    {
                        title: 'Registrerte avvik siste måned',
                        field: 'nOfRegisteredDeviationsLast31Days'
                    }
                ]}
        ]},
    {rows: [
            {title: 'Ressurser', data: [
                    {
                        title: 'Ant. totalt',
                        field: 'nOfResources'
                    },
                    {
                        title: 'Aktive',
                        field: 'nOfActiveResources'
                    },
                    {
                        title: 'Ant. nøter',
                        field: 'nOfNets'
                    },
                    {
                        title: 'Ant. aktive nøter',
                        field: 'nOfActiveNets'
                    },
                    {
                        title: 'Ant. kaserte nøter',
                        field: 'nOfDiscardedNets'
                    },
                    {
                        title: 'Ant. nøter i havet',
                        field: 'nOfNetsInUse'
                    },
                    {
                        title: 'Ant. nøter på service',
                        field: 'nOfNetsInServiceStorage'
                    },
                    {
                        title: 'Ant. Flytere',
                        field: 'nOfFloaters'
                    },
                    {
                        title: 'Ant. aktive flytere',
                        field: 'nOfActiveFloaters'
                    },
                    {
                        title: 'Ant. kasserte flytere',
                        field: 'nOfDiscardedFloaters'
                    },
                    {
                        title: 'Ant. flytere på service',
                        field: 'nOfFloatersInServiceStorage'
                    },
                    {
                        title: 'Ant. luseskjørt',
                        field: 'nOfLiceSkirts'
                    },
                    {
                        title: 'Snittalder for Not',
                        field: 'avgAgeNet'
                    },
                    {
                        title: 'Snittalder for Flyter',
                        field: 'avgAgeFloater'
                    },
                    {
                        title: 'Snittalder for Luseskjørt',
                        field: 'avgAgeLiceSkirt'
                    }
                ]}
        ]}
];

const Summary = ({isActiveView}) => {
    
    const { isLoading: isLoadingStatisticsData, data: statisticsData } = useQuery([queryConstants.fetchStatisticsData, isActiveView], () => http.get(`statuslog/summary/getStatistics`), {enabled: isActiveView, placeholderData: {}})

    return (
        <>
            <br/>
            <Grid container alignItems="flex-start" spacing={3}>
                {summaryRows.map((d2, index1) => (
                        <Grid item xs={4} key={index1}>
                            {d2.rows.map((summaryRows, index2) => (
                                <Grid item xs={12} key={index2}>
                                    {index2 !== 0 ? (<br/>) : null}
                                    <Typography variant="subtitle1" component="h2">
                                        {summaryRows.title}
                                    </Typography>
                                    <TableContainer elevation={3} component={Paper}>
                                        <Table aria-label="simple table">
                                            <TableBody>
                                                {summaryRows.data.map((data, index3) => (
                                                    <TableRow key={index3}>
                                                        <TableCell component="th" scope="row">{data.title}</TableCell>
                                                        <TableCell align="right">{statisticsData && statisticsData[data?.field]}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            ))}
                        </Grid>
                    )
                )}
            </Grid>
        </>
    )
}

export default Summary;