import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

let menuItems = [
    {
        uri: '/',
        text: 'Hjem',
        external: false,
    },
    {
        uri: '/',
        text: 'Om oss',
        external: false,
    }];

const styles = theme => ({
    menu: {
        float: 'left',
        fontSize: 0,
        listStyleType: 'none',
        zIndex: 101,
        padding: 0,
        paddingLeft: 10,
        height: '100%',
        '& li': {
            fontFamily: '"Open Sans",sans-serif!important',
            fontSize: '15px!important',
            fontWeight: '400!important',
            color: '#333!important',
            display: 'inline-block',
            position: 'relative',
            zIndex: 50,
            verticalAlign: 'middle',
            lineHeight: '60px',
            float: 'left',
            listStyleType: 'none',
            '& a': {
                textDecoration: 'none',
                color: 'inherit',
                width: '100%',
                height: '100%',
                padding: 15
            },
        },
        '& li:hover': {
            backgroundColor: '#f2f2f2',
        }
    }
});

function Navigator(props) {
    let user = JSON.parse(localStorage.getItem('user'));
    const { classes, ...other } = props;
    return (
        <div>
            <ul className={classes.menu}>
                {menuItems && menuItems.map((item, index) => (
                    <li key={index}>
                        <a href={item.uri}>
                            {item.text}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
}

Navigator.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigator);