import axios from "axios";
import LocalStorageService  from "../Storage/LocalStorageService";
import swal from 'sweetalert';
import {toast} from "react-toastify";

// Create axios instance
let baseUrl = 'https://api.havbruksloggen.no/api/';

const axiosInstance = axios.create({
    baseURL: baseUrl
});

axiosInstance.interceptors.request.use(
    config => {
        const token = LocalStorageService.getAccessToken();
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (401 === error.response.status) {
            swal({
                title: "Session Expired",
                text: "Your session has expired. Would you like to be redirected to the login page?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                closeOnConfirm: false
            }).then(function(){
                LocalStorageService.clearToken();
                window.location = '/login';
            });
        } else {
            toast(error.response.data, { type: "error" });
        }
        return Promise.reject(error);
    }
);
export { axiosInstance };