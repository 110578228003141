import React from 'react';
import {Grid} from '@material-ui/core';


export default props => (
    <div>
        <Grid container>
            {props.children}
        </Grid>
    </div>
);