import React from 'react';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from 'prop-types';
import Locations from "./SubPages/Locations";
import MainComponents from "./SubPages/MainComponents";
import Summary from "./SubPages/Summary";
import Typography from "@material-ui/core/Typography";
import Environment from "./SubPages/Environment";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Dashboard() {
    const [tabValue, setTabValue] = React.useState(0);
    
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <div>
            <AppBar position="static">
                <Tabs value={tabValue} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Lokalitet" {...a11yProps(0)} />
                    <Tab label="Hovedkomponenter" {...a11yProps(1)} />
                    <Tab label="Miljø" {...a11yProps(2)} />
                    <Tab label="Fakta" {...a11yProps(3)} />
                </Tabs>
            </AppBar>
            <TabPanel value={tabValue} index={0}>
                <Locations isActiveView={tabValue === 0} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <MainComponents isActiveView={tabValue === 1} />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                <Environment isActiveView={tabValue === 2} />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
                <Summary isActiveView={tabValue === 3} />
            </TabPanel>
            
        </div>
    );
    
}

export default Dashboard