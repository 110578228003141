import Typography from "@material-ui/core/Typography";
import React from "react";
import {useQuery} from "react-query";
import http from "../../Services/Network/http";
import {DataGridPro} from "@mui/x-data-grid-pro";

const queryConstants = {
    fetchFacilityEnvironmentSummaries: "fetchFacilityEnvironmentSummaries",
};

const Environment = ({isActiveView}) => {
    const { isLoading: isLoadingFacilityEnvironmentSummariesData, data: facilityEnvironmentSummariesData } = useQuery([queryConstants.fetchLocationDeviationData], () => http.get(`statuslog/environment/getFacilityEnvironmentSummaries`), { placeholderData: []})

    return (
        <>
            <br/>
            <Typography variant="h5" component="h2">
                Miljø anlegg
            </Typography>
            <br/>
            
            <DataGridPro pageSize={50} pagination={true} loading={isLoadingFacilityEnvironmentSummariesData} getRowId={(row) => row.facilityId} columns={
                [
                    { headerName: "AnleggId", field: "facilityId", hide: true},
                    { headerName: "Lokalitet", field: "locationName", flex: true },
                    { headerName: "Lokalitetskode", field: "locationNo", flex: true },
                    { headerName: "Anlegg", field: "facilityName", flex: true },
                    { headerName: "Beskrivelse", field: "facilityDescription", flex: true },
                    { headerName: "Type", field: "facilityType", flex: true },
                    { headerName: "Antall kg plast", field: "plasticWeightKG", flex: true },
                ]
            } rows={facilityEnvironmentSummariesData || []} rowHeight={34} headerHeight={56}  autoHeight={true} scrollbarSize={200} columnBuffer={2} sortingOrder={['asc', 'desc', null]} columnTypes={[]} density={"standard"} localeText={[]} />
        </>)
}

export default Environment;