import Grid from "@material-ui/core/Grid";
import RechartsPie from "../../Components/RechartsPie";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

import Moment from "react-moment";
import React, {useEffect} from "react";
import {useQuery} from "react-query";
import http from "../../Services/Network/http";
import _ from "lodash";
import moment from "moment";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import {Button} from "@material-ui/core";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";
import {DataGridPro} from "@mui/x-data-grid-pro";

const queryConstants = {
    fetchLocationPieData: "fetchLocationPieData",
    fetchLocationDeviationData: "fetchLocationDeviationData",
    fetchFacilitiesServiceData: "fetchFacilitiesServiceData",
    fetchFacilitiesCertificationData: "fetchFacilitiesCertificationData",
    fetchTaskOccurrenceData: "fetchTaskOccurrenceData"
};

const Locations = ({isActiveView}) => {
    
    const [selectedPieSlice, setSelectedPieSlice] = React.useState({type: null, index: null, data: null});

    const { data: locationPieData } = useQuery(queryConstants.fetchLocationPieData, () => http.get("statuslog/locations/getPies"))

    const { isLoading: isLoadingDeviationData, data: locationDeviationData } = useQuery([queryConstants.fetchLocationDeviationData, selectedPieSlice?.data?.keyValue], () => http.get(`statuslog/locations/getDeviationByStatusId/${selectedPieSlice?.data?.keyValue}`), {enabled: selectedPieSlice.type === 1 && selectedPieSlice?.data?.keyValue !== undefined, placeholderData: []})
    const { isLoading: isLoadingFacilitiesServiceData, data: facilitiesServiceData } = useQuery([queryConstants.fetchFacilitiesServiceData, selectedPieSlice.type], () => http.get(`statuslog/locations/getFacilitiesServices`), {enabled: selectedPieSlice.type === 2, placeholderData: []})
    const { isLoading: isLoadingFacilitiesCertificationData, data: facilitiesCertificationData } = useQuery([queryConstants.fetchFacilitiesCertificationData, selectedPieSlice.type], () => http.get(`statuslog/locations/getFacilities`), {enabled: selectedPieSlice.type === 3, placeholderData: []})
    const { isLoading: isLoadingTaskOccurrenceData, data: taskOccurrenceData } = useQuery([queryConstants.fetchTaskOccurrenceData, selectedPieSlice.type], () => http.get(`statuslog/locations/getTaskOccurrences`), {enabled: selectedPieSlice.type === 4, placeholderData: []})

    const [filteredFacilityCertificationData, setFilteredFacilityCertificationData] = React.useState([]);
    const [filteredFacilitiesServicesData, setFilteredFacilitiesServicesData] = React.useState([]);
    const [filteredTaskOccurrencesData, setFilteredTaskOccurrencesData] = React.useState([]);
    useEffect(() => {
        console.log(selectedPieSlice?.data)
    }, [selectedPieSlice])
    useEffect(() => {
        if (selectedPieSlice.type === 2) {
            switch (selectedPieSlice.data.keyValue) {
                case 4:
                    setFilteredFacilitiesServicesData(facilitiesServiceData?.filter(x=>new Date(x.dateDue) > Date.now() && new Date(x.dateDue) < new Date(new Date().setDate(new Date().getDate() + 365)) && x.completed === false));
                    break;
                case 3:
                    setFilteredFacilitiesServicesData(facilitiesServiceData?.filter(x=>new Date(x.dateDue) > Date.now() && new Date(x.dateDue) < new Date(new Date().setDate(new Date().getDate() + 90)) && x.completed === false));
                    break;
                case 2:
                    setFilteredFacilitiesServicesData(facilitiesServiceData?.filter(x=>new Date(x.dateDue) < Date.now() && x.completed === false));
                    break;
                case 1:
                    setFilteredFacilitiesServicesData(facilitiesServiceData?.filter(x=> x.completed));
                    break;
                default:
                    setFilteredFacilitiesServicesData(facilitiesServiceData);
                    break;
            }
        } else if (selectedPieSlice.type === 3) {
            switch (selectedPieSlice.data.keyValue) {
                case 1:
                    setFilteredFacilityCertificationData(facilitiesCertificationData?.filter(x=>new Date(x.certifiedUntilDateTime) > Date.now() && x.facilityType !== 5));
                    break;
                case 2:
                    setFilteredFacilityCertificationData(facilitiesCertificationData?.filter(x=>(new Date(x.certifiedUntilDateTime) < Date.now() || x.certifiedUntilDateTime === null) && x.facilityType !== 5));
                    break;
                case 3:
                    setFilteredFacilityCertificationData(facilitiesCertificationData?.filter(x=>new Date(x.certifiedUntilDateTime) < new Date(new Date().setDate(new Date().getDate() + 180)) && new Date(x.certifiedUntilDateTime) > Date.now() && x.facilityType !== 5 && x.certifiedUntilDateTime !== null));
                    break;
                default:
                    setFilteredFacilityCertificationData(facilitiesCertificationData);
                    break;
            }
        } else if (selectedPieSlice.type === 4) {
            switch (selectedPieSlice.data.keyValue) {
                case 1:
                    setFilteredTaskOccurrencesData(taskOccurrenceData?.filter(x=>x.signed));
                    break;
                case 2:
                    setFilteredTaskOccurrencesData(taskOccurrenceData?.filter(x=>new Date(x.startDate) < Date.now() && !x.signed));
                    break;
                case 3:
                    setFilteredTaskOccurrencesData(taskOccurrenceData?.filter(x=>!x.signed));
                    break;
                default:
                    setFilteredTaskOccurrencesData(taskOccurrenceData);
                    break;
            }
        }
    }, [selectedPieSlice,facilitiesCertificationData, facilitiesServiceData, taskOccurrenceData]);
    
    let statsMonths = [];
    Object.entries(_.groupBy(locationDeviationData?.filter(x=>new Date(x.registeredDateTime) > new Date(new Date().setDate(new Date().getDate() - 365))), 
        (result) => moment(new Date(result['registeredDateTime']), 'YYYY-MM-DD').startOf('months'))).forEach(entry => {
            const [key, value] = entry;
            let m = new Date(key).toLocaleString('default', {month: 'short'});
            let y = new Date(key).toLocaleString('default', {year: 'numeric'});
            statsMonths.push({month: `${m}/${y}`, value: value.length});
    })
    
    return (
        <div>
            <br/>
            <Grid container justify="center" spacing={2}>
                <Grid item xs={3}>
                    <RechartsPie data={(locationPieData?.deviationPieData || []).map(x => ({name: x.title, value: x.value, color: x.color, keyValue: x.keyValue}))} title={"Avvik"} height={350} pieType={1} selectedPieSlice={selectedPieSlice} setSelectedPieSlice={setSelectedPieSlice} />
                </Grid>
                <Grid item xs={3}>
                    <RechartsPie data={(locationPieData?.taskOccurrencePieData || []).map(x => ({name: x.title, value: x.value, color: x.color, keyValue: x.keyValue}))} title={"Oppgaver"} height={350} pieType={4} selectedPieSlice={selectedPieSlice} setSelectedPieSlice={setSelectedPieSlice} />
                </Grid>
                <Grid item xs={3}>
                    <RechartsPie data={(locationPieData?.servicePieData || []).map(x => ({name: x.title, value: x.value, color: x.color, keyValue: x.keyValue}))} title={"Service"} height={350} pieType={2} selectedPieSlice={selectedPieSlice} setSelectedPieSlice={setSelectedPieSlice} />
                </Grid>
                <Grid item xs={3}>
                    <RechartsPie data={(locationPieData?.facilityCertificatesPieData || []).map(x => ({name: x.title, value: x.value, color: x.color, keyValue: x.keyValue}))} title={"Anleggssertifisering"} pieType={3} height={350} selectedPieSlice={selectedPieSlice} setSelectedPieSlice={setSelectedPieSlice} />
                </Grid>
            </Grid>
            <br/>
            <br/>
            <hr/>
            {/* deviation */}
            {selectedPieSlice.type === 1 &&
            <>
                <Typography variant="h5" component="h2" style={{float: 'left'}}>
                    {selectedPieSlice.index !== 0 ? "Lukkede avvik" : "Åpne avvik"}
                </Typography>
                <ExcelFile element={<Button style={{float: 'right'}}>Export to excel</Button>}>
                    <ExcelSheet data={locationDeviationData.map((item) => {
                        return {
                            ...item,
                            txtArticleNr: item.facilityPositionItem?.articleNr,
                            txtArticleDescription: item.facilityPositionItem?.articleDescription,
                            txtLocationName: item.location?.name,
                            txtPlacement: (item.facilityPosition?.position||'') +  ' | ' + (item.facilityPositionItem?.articleNr || '') + ' - ' + (item.facilityPositionItem?.articleDescription||''),
                            txtResponsiblePerson: item.responsibleUser?.fullName,
                            txtPriority: item.deviationPriority?.title
                        }
                    }) || []} name="Avvik">
                        <ExcelColumn label="Id" value="id"/>
                        <ExcelColumn label="Prioritet" value="txtPriority"/>
                        <ExcelColumn label="Posisjon|Komponent" value="txtPlacement"/>
                        <ExcelColumn label="Tittel" value="title"/>
                        <ExcelColumn label="Lokasjon" value="txtLocationName"/>
                        <ExcelColumn label="Ansvarlig" value="txtResponsiblePerson"/>
                        <ExcelColumn label="Registrert dato" value="registeredDateTime"/>
                        <ExcelColumn label="Lukket dato" value="closedDateTime"/>
                        <ExcelColumn label="Forfalls dato" value="dueDateTime"/>
                    </ExcelSheet>
                </ExcelFile>
                <div style={{clear: "both"}} />
                <br/>
                <Paper elevation={1}>
                    <ResponsiveContainer height={350}>
                        <BarChart
                            data={statsMonths}
                            margin={{
                                top: 20, right: 20, left: 0, bottom: 20,
                            }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis dataKey="value" />
                            <Tooltip />
                            <Bar dataKey="value" label="Antall avvik" fill="#00526b" />
                        </BarChart>
                    </ResponsiveContainer>
                </Paper>
                <br/>
                <DataGridPro pageSize={50} pagination={true} loading={isLoadingDeviationData} columns={
                    [
                        { headerName: "Id", field: "id", flex: true },

                        { headerName: "Prioritet", field: "deviationPriorityId", renderCell: rowData => (<div>{rowData.row.deviationPriority?.title}</div>) },
                        { headerName: "Posisjon|Komponent", field: "posistion", renderCell: rowData => (<div>{rowData.row.facilityPosition?.position} | {rowData.row.facilityPositionItem?.articleNr} - {rowData.row.facilityPositionItem?.articleDescription} </div>), flex: true },
                        { headerName: "Tittel", field: "title", flex: true },

                        { headerName: "Lokasjon navn", field: "location.name", renderCell: rowData => (<div>{rowData.row.location?.name}</div>), flex: true },
                        { headerName: "Ansvarlig", field: "responsibleUser.fullName", renderCell: rowData => (<div>{rowData.row.responsibleUser?.fullName}</div>), flex: true },
                        { headerName: "Registrert dato", field: "registeredDateTime", renderCell: rowData => (<Moment format="DD/MM/YYYY">{rowData.row.registeredDateTime}</Moment>), flex: true },
                        { headerName: "Lukket dato", field: "closedDateTime", renderCell: rowData => (<Moment format="DD/MM/YYYY">{rowData.row.closedDateTime}</Moment>), hide: (selectedPieSlice.data.keyValue === 1), flex: true },
                        { headerName: "Forfalls dato", field: "dueDateTime", renderCell: rowData => (<Moment format="DD/MM/YYYY">{rowData.row.dueDateTime}</Moment>), flex: true },
                    ]
                } rows={locationDeviationData || []} rowHeight={34} headerHeight={56}  autoHeight={true} scrollbarSize={200} columnBuffer={2} sortingOrder={['asc', 'desc', null]} columnTypes={[]} density={"standard"} localeText={[]} />
            </>
            }
            { /* facilities services */ }
            {selectedPieSlice.type === 2 &&
            <>
                <Typography variant="h5" component="h2" style={{float: 'left'}}>
                    Servicer
                </Typography>
                <ExcelFile element={<Button  style={{float: 'right'}}>Export to excel</Button>}>
                    <ExcelSheet data={filteredFacilitiesServicesData || []} name="Servicer">
                        <ExcelColumn label="Tidsfrist" value="dateDue"/>
                        <ExcelColumn label="Service type" value="serviceType"/>
                        <ExcelColumn label="Tittel" value="title"/>
                    </ExcelSheet>
                </ExcelFile>
                <div style={{clear: "both"}} />
                <br/>
                <DataGridPro pageSize={50} pagination={true} loading={isLoadingFacilitiesServiceData} columns={
                    [
                        { headerName: "Tidsfrist", field: "dateDue", renderCell: rowData => (<Moment format="DD/MM/YYYY">{rowData.row.dateDue}</Moment>), flex: true },
                        { headerName: "Service type", field: "serviceType", flex: true },
                        { headerName: "Tittel", field: "title", flex: true },
                    ]
                } rows={filteredFacilitiesServicesData || []} rowHeight={34} headerHeight={56}  autoHeight={true} scrollbarSize={200} columnBuffer={2} sortingOrder={['asc', 'desc', null]} columnTypes={[]} density={"standard"} localeText={[]} />
            </>
            }

            { /* facilities certifications */ }
            {selectedPieSlice.type === 3 &&
            <>
                <Typography variant="h5" component="h2" style={{float: 'left'}}>
                    Anleggssertifikater
                </Typography>
                <ExcelFile element={<Button  style={{float: 'right'}}>Export to excel</Button>}>
                    <ExcelSheet data={filteredFacilityCertificationData.map((item) => {
                        let a = moment(item.certifiedUntilDateTime);
                        let now = moment(new Date());
                        let days = a.diff(now, 'days');
                        return {
                            ...item,
                            txtDaysLeft: days
                        }
                    }) || []} name="Servicer">
                        <ExcelColumn label="Anlegg" value="name"/>
                        <ExcelColumn label="Beskrivelse/type" value="description"/>
                        <ExcelColumn label="Pålitelighetsklasse" value="reliabilityClass"/>
                        <ExcelColumn label="Sert. til" value="certifiedUntilDateTime"/>
                        <ExcelColumn label="Sertifikat utløper om" value="txtDaysLeft"/>

                    </ExcelSheet>
                </ExcelFile>
                <div style={{clear: "both"}} />
                <br/>
                <DataGridPro pageSize={50} pagination={true} loading={isLoadingFacilitiesCertificationData} columns={
                    [
                        { headerName: "Anlegg", field: "name", flex: true },
                        { headerName: "Beskrivelse/type", field: "description", flex: true },
                        { headerName: "Pålitelighetsklasse", field: "reliabilityClass", flex: true },
                        { headerName: "Sert. til", field: "certifiedUntilDateTime", renderCell: rowData => rowData.row.certifiedUntilDateTime != null ? (<Moment format="DD/MM/YYYY">{rowData.row.certifiedUntilDateTime}</Moment>) : (<span style={{color: 'red'}}>Ikke sertifisert</span>), flex: true },
                        { headerName: "Sertifikat utløper om", field: "", renderCell: rowData => rowData.row.certifiedUntilDateTime != null ? (new Date(rowData.row.certifiedUntilDateTime)).getTime() < (new Date()).getTime() ? (<span style={{color: 'red'}}>Utløpt</span>) : (<div><Moment diff={Date.now()} unit="days">{rowData.row.certifiedUntilDateTime}</Moment> dager</div>) : (<span style={{color: 'red'}}>Ikke sertifisert</span>), flex: true },
                    ]
                } rows={filteredFacilityCertificationData || []} rowHeight={34} headerHeight={56}  autoHeight={true} scrollbarSize={200} columnBuffer={2} sortingOrder={['asc', 'desc', null]} columnTypes={[]} density={"standard"} localeText={[]} />
            </>
            }

            { /* task occurences */ }
            {selectedPieSlice.type === 4 &&
            <>
                <Typography variant="h5" component="h2" style={{float: 'left'}}>
                    Oppgaver
                </Typography>
                <ExcelFile element={<Button style={{float: 'right'}}>Export to excel</Button>}>
                    <ExcelSheet data={filteredTaskOccurrencesData.map((item) => {
                        return {
                            ...item,
                            txtFacilityName: item.facility?.name,
                            txtAnsvarligName: item.responsibleUser?.fullName,
                            txtSignName: item.signedByUser?.fullName
                        }
                    }) || []} name="Avvik">
                        <ExcelColumn label="Occurrence.Id" value="taskOccurrenceId"/>
                        <ExcelColumn label="Oppgave.Id" value="taskId"/>
                        <ExcelColumn label="Tittel" value="title"/>
                        <ExcelColumn label="Start" value="startDate"/>
                        <ExcelColumn label="Stopp" value="endDate"/>
                        <ExcelColumn label="Anlegg" value="txtFacilityName"/>
                        <ExcelColumn label="Ansvarlig" value="txtAnsvarligName"/>
                        <ExcelColumn label="Signert" value="txtSignName"/>
                    </ExcelSheet>
                </ExcelFile>
                <div style={{clear: "both"}} />
                <br/>
                <DataGridPro pageSize={50} pagination={true} loading={isLoadingTaskOccurrenceData} getRowId={(row) => row.taskOccurrenceId} columns={
                    [
                        { headerName: "Occurence.Id", field: "taskOccurrenceId", hide: true, flex: true },
                        { headerName: "Oppgave.Id", field: "taskId", flex: true },
                        { headerName: "Tittel", field: "title", flex: true },
                        { headerName: "Start", field: "startDate", renderCell: rowData => (<Moment format="DD/MM/YYYY">{rowData.row.startDate}</Moment>), flex: true },
                        { headerName: "Stopp", field: "endDate", renderCell: rowData => (<Moment format="DD/MM/YYYY">{rowData.row.endDate}</Moment>), flex: true },
                        { headerName: "Anlegg", field: "facilityId", renderCell: rowData => (<div>{rowData.row.facility?.name}</div>), flex: true },
                        { headerName: "Ansvarlig", field: "", renderCell: rowData => (<div>{rowData.row.responsibleUser?.fullName}</div>), flex: true },
                        { headerName: "Signert", field: "signed", renderCell: rowData => (<div>{rowData.row.signedByUser?.fullName}</div>), flex: true },
                    ]
                } rows={filteredTaskOccurrencesData || []} rowHeight={34} headerHeight={56}  autoHeight={true} scrollbarSize={200} columnBuffer={2} sortingOrder={['asc', 'desc', null]} columnTypes={[]} density={"standard"} localeText={[]} />
            </>
            }
        </div>
    )
}

export default Locations;