import React from 'react';
import Layout from "./Components/Template/Layout";
import Dashboard from "./Pages/Dashboard";
import {PrivateRoute} from "./helpers/PrivateRoute";
import Login from "./Pages/Login";
import {Route, Switch} from "react-router-dom";
import NoLayout from "./Components/Template/NoLayout";
import {withRouter} from "react-router";
import { QueryClient, QueryClientProvider } from 'react-query'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { LicenseInfo } from '@mui/x-data-grid-pro';

LicenseInfo.setLicenseKey(
    'a07b32847f8f127b8088ce41ad3ee868T1JERVI6MzE4NjYsRVhQSVJZPTE2Njc0NjY1NTgwMDAsS0VZVkVSU0lPTj0x',
);


const queryClient = new QueryClient()


export default () => (
    <div>
        <QueryClientProvider client={queryClient}>
        <Switch>
            <Route exact path="/login" component={withRouter(EmptyLayout)}/>
            <Route path="*" component={withRouter(ThemedLayout)}/>
        </Switch>
        </QueryClientProvider>
        <ToastContainer/>
    </div>
)

const EmptyLayout = () => (
    <NoLayout>
        <Route exact path="/login" component={Login}/>
    </NoLayout>
    
);

const ThemedLayout = () => (
    <Layout>
        <PrivateRoute exact path='/' component={Dashboard}/>
        <PrivateRoute exact path='/Dashboard' component={Dashboard}/>
        <Route exact path='/Dashboard1' component={Dashboard}/>
    </Layout>
);

