import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Spinner} from "react-redux-spinner";
import Navigator from "./Navigator";

const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = theme => ({
    header: {
        width: 1258,
        height: 60,
        margin: '0 auto',
        boxShadow: '0 4px 12px rgba(0,0,0,.1)',
        backgroundColor: '#f9f9f9',
        zIndex: 100,
        paddingLeft: 10,
        paddingRight: 10,
    },
    logo: {
        height: 60,
        padding: 10,
        float: 'left'
    }
});



function Header(props) {
    let user = JSON.parse(localStorage.getItem('user'));
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { classes, onDrawerToggle } = props;

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        localStorage.removeItem("user");
        handleClose();
    };

    return (
        <React.Fragment>
            <div className={classes.header}>
                <a href={'/'}>
                    <img className={classes.logo} alt="logo" src={'/logo_statusloggen.png'} />
                </a>
                <Navigator />
            </div>
            <Spinner  config={{parent: "#AppBar"}}/>
            {/*<AppBar
                component="div"
                className={classes.secondaryBar}
                color="primary"
                position="static"
                elevation={0}>
                <Tabs value={0} textColor="inherit">
                    <Tab textColor="inherit" label="Users" />
                    <Tab textColor="inherit" label="Sign-in method" />
                    <Tab textColor="inherit" label="Templates" />
                    <Tab textColor="inherit" label="Usage" />
                </Tabs>
            </AppBar>*/}
        </React.Fragment>
    );
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    onDrawerToggle: PropTypes.func.isRequired,
};

export default withStyles(styles)(Header);