import Grid from "@material-ui/core/Grid";
import RechartsPie from "../../Components/RechartsPie";
import React from "react";
import {useQuery} from "react-query";
import http from "../../Services/Network/http";
import Typography from "@material-ui/core/Typography";
import Moment from "react-moment";
import moment from 'moment';
import Paper from "@material-ui/core/Paper";
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import _ from "lodash";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";
import {Button} from "@material-ui/core";
import {DataGridPro} from "@mui/x-data-grid-pro";

const queryConstants = {
    fetchMainComponentsPieData: "fetchMainComponentsPieData",
    fetchMainComponentsByStatus: "fetchMainComponentsByStatus",
    fetchDeviationsByStatus: "fetchDeviationsByStatus",
    fetchMainComponentsByType: "fetchMainComponentsByType",
    fetchMainComponentsNetsByCertification: "fetchMainComponentsNetsByCertification"
}

const statusTitles = (selectedPieSliceKeyValue) => {
    let title = "";
    switch(selectedPieSliceKeyValue) {
        case 1:
            title = "Aktiv";
            break;
        case 2:
            title = "På lager"
            break;
        case 3:
            title = "I service"
            break;
        default:
            break;
    }
    return title;
}

const MainComponents = ({isActiveView}) => {
    
    const [selectedPieSlice, setSelectedPieSlice] = React.useState({type: null, index: null, data: null});
    const { data: mainComponentsPieData } = useQuery(queryConstants.fetchMainComponentsPieData, () => http.get("statuslog/mainComponents/getPies"))
    const { isLoading: isLoadingResourcesByStatuses, data: resourcesByStatusesData } = 
        useQuery(
            [queryConstants.fetchMainComponentsByStatus, selectedPieSlice?.data?.keyValue], 
            () => {
                let statuses = [];
                switch (selectedPieSlice?.data?.keyValue) {
                    case 1:
                        statuses.push(2);
                        break;
                    case 2:
                        statuses.push(6, 7);
                        break;
                    case 3:
                        statuses.push(3, 5);
                        break;
                    default:
                        break;
                        
                }
                return http.get(`statuslog/mainComponents/getResourcesByStatuses`, { resourceStatuses: statuses })
            }, 
            {enabled: selectedPieSlice.type === 1 && selectedPieSlice?.data?.keyValue !== undefined, placeholderData: []}
        );

    const { isLoading: isLoadingResourcesByType, data: resourcesByTypeData } =
        useQuery(
            [queryConstants.fetchMainComponentsByType, selectedPieSlice?.data?.keyValue],
            () => {
                let keyValue = selectedPieSlice?.data?.keyValue;
                return http.get(`statuslog/mainComponents/getResourcesByType/${keyValue !== 4 ? keyValue : null}`)
            },
            { enabled: selectedPieSlice.type === 3 && selectedPieSlice?.data?.keyValue !== undefined, placeholderData: [] }
        );

    const { isLoading: isLoadingResourceNetsByCertification, data: resourceNetsByCertificationData } =
        useQuery(
            [queryConstants.fetchMainComponentsNetsByCertification, selectedPieSlice?.data?.keyValue],
            () => {
                let keyValue = selectedPieSlice?.data?.keyValue;
                return http.get(`statuslog/mainComponents/getResourceNetsByCertification/${keyValue}`)
            },
            { enabled: selectedPieSlice.type === 4 && selectedPieSlice?.data?.keyValue !== undefined, placeholderData: [] }
        );
    
    const { isLoading: isLoadingDeviationData, data: deviationData } = useQuery([queryConstants.fetchDeviationsByStatus, selectedPieSlice?.data?.keyValue], () => http.get(`statuslog/mainComponents/getDeviationByStatusId/${selectedPieSlice?.data?.keyValue}`), {enabled: selectedPieSlice.type === 2 && selectedPieSlice?.data?.keyValue !== undefined, placeholderData: []})


    console.log(selectedPieSlice);

    let statsMonths = [];
    Object.entries(_.groupBy(deviationData?.filter(x=>new Date(x.registeredDateTime) > new Date(new Date().setDate(new Date().getDate() - 365))),
        (result) => moment(new Date(result['registeredDateTime']), 'YYYY-MM-DD').startOf('months'))).forEach(entry => {
        const [key, value] = entry;
        let m = new Date(key).toLocaleString('default', {month: 'short'});
        let y = new Date(key).toLocaleString('default', {year: 'numeric'});
        statsMonths.push({month: `${m}/${y}`, value: value.length});
    });
    
    return (
        <div>
            <br/>
            <Grid container justify="center" spacing={2}>
                <Grid item xs={3}>
                    <RechartsPie data={(mainComponentsPieData?.statusPieData || []).map(x => ({name: x.title, value: x.value, color: x.color, keyValue: x.keyValue}))} title={"Status"} height={350} pieType={1} selectedPieSlice={selectedPieSlice} setSelectedPieSlice={setSelectedPieSlice} />
                </Grid>
                <Grid item xs={3}>
                    <RechartsPie data={(mainComponentsPieData?.deviationPieData || []).map(x => ({name: x.title, value: x.value, color: x.color, keyValue: x.keyValue}))} title={"Avvik"} height={350} pieType={2} selectedPieSlice={selectedPieSlice} setSelectedPieSlice={setSelectedPieSlice} />
                </Grid>
                <Grid item xs={3}>
                    <RechartsPie data={(mainComponentsPieData?.typesPieData || []).map(x => ({name: x.title, value: x.value, color: x.color, keyValue: x.keyValue}))} title={"Fordeling"} height={350} pieType={3} selectedPieSlice={selectedPieSlice} setSelectedPieSlice={setSelectedPieSlice} />
                </Grid>
                <Grid item xs={3}>
                    <RechartsPie data={(mainComponentsPieData?.netCertificatesPieData || []).map(x => ({name: x.title, value: x.value, color: x.color, keyValue: x.keyValue}))} title={"Not sertifisering"} pieType={4} height={350} selectedPieSlice={selectedPieSlice} setSelectedPieSlice={setSelectedPieSlice} />
                </Grid>
            </Grid>
            <hr/>
            {selectedPieSlice.type === 1 &&
            <>
                <Typography variant="h5" component="h2" style={{float:'left'}}>
                    {statusTitles(selectedPieSlice.data.payload.keyValue)}
                </Typography>
                <ExcelFile element={<Button  style={{float: 'right'}}>Export to excel</Button>}>
                    <ExcelSheet data={resourcesByStatusesData.map((item) => {
                        return {
                            ...item,
                            txtStatus: item.resourceStatus?.title,
                            txtResourceType: item.resourceType?.title,
                            txtLocation: item.location?.name
                        }
                    }) || []} name="Ressurser">
                        <ExcelColumn label="Id" value="id"/>
                        <ExcelColumn label="Type" value="txtResourceType"/>
                        <ExcelColumn label="Navn" value="name"/>
                        <ExcelColumn label="Produksjonsnummer" value="serialNumber"/>
                        <ExcelColumn label="Produsert" value="productionYear"/>
                        <ExcelColumn label="Status" value="txtStatus"/>
                        <ExcelColumn label="Omkrets" value="circumference"/>
                        <ExcelColumn label="Lokalitet" value="txtLocation"/>
                    </ExcelSheet>
                </ExcelFile>
                <div style={{clear: "both"}} />
                <br/>
                <DataGridPro pageSize={50} pagination={true} loading={isLoadingResourcesByStatuses} columns={
                    [
                        { headerName: "Id", field: "id" },

                        { headerName: "Type", field: "ResourceTypeId", renderCell: rowData => (<div>{ rowData.row.resourceType?.title }</div>), flex: true },
                        { headerName: "Navn", field: "name" },
                        { headerName: "Produksjonsnummer", field: "serialNumber", flex: true },
                        { headerName: "Produsert", field: "productionYear", flex: true },
                        { headerName: "Status", field: "resourceStatusId", renderCell: rowData => (<div>{rowData.row.resourceStatus?.title}</div>), flex: true },
                        { headerName: "Omkrets", field: "circumference", flex: true},
                        { headerName: "Lokalitet", field: "locationId", renderCell: rowData => (<div>{rowData.row.location?.name}</div>), flex: true }
                    ]
                } rows={resourcesByStatusesData || []} rowHeight={34} headerHeight={56}  autoHeight={true} scrollbarSize={200} columnBuffer={2} sortingOrder={['asc', 'desc', null]} columnTypes={[]} density={"standard"} localeText={[]} />
            </>
            }
            {selectedPieSlice.type === 2 &&
            <>
                <Typography variant="h5" component="h2" style={{ float: 'left' }}>
                    {selectedPieSlice.index !== 0 ? "Lukkede avvik" : "Åpne avvik"}
                </Typography>
                <ExcelFile element={<Button  style={{float: 'right'}}>Export to excel</Button>}>
                    <ExcelSheet data={deviationData.map((item) => {
                        return {
                            ...item,
                            txtArticleNr: item.facilityPositionItem?.articleNr,
                            txtArticleDescription: item.facilityPositionItem?.articleDescription,
                            txtLocationName: item.location?.name,
                            txtPlacement: (item.facilityPosition?.position||'') +  ' | ' + (item.facilityPositionItem?.articleNr || '') + ' - ' + (item.facilityPositionItem?.articleDescription||''),
                            txtResponsiblePerson: item.responsibleUser?.fullName,
                            txtPriority: item.deviationPriority?.title
                        }
                    }) || []} name="Avvik">
                        <ExcelColumn label="Id" value="id"/>
                        <ExcelColumn label="Prioritet" value="txtPriority"/>
                        <ExcelColumn label="Posisjon|Komponent" value="txtPlacement"/>
                        <ExcelColumn label="Tittel" value="title"/>
                        <ExcelColumn label="Lokasjon" value="txtLocationName"/>
                        <ExcelColumn label="Ansvarlig" value="txtResponsiblePerson"/>
                        <ExcelColumn label="Registrert dato" value="registeredDateTime"/>
                        <ExcelColumn label="Lukket dato" value="closedDateTime"/>
                        <ExcelColumn label="Forfalls dato" value="dueDateTime"/>
                    </ExcelSheet>
                </ExcelFile>
                <div style={{clear: "both"}} />
                <br/>
                <Paper elevation={1}>
                    <ResponsiveContainer height={350}>
                        <BarChart
                            data={statsMonths}
                            margin={{
                                top: 20, right: 20, left: 0, bottom: 20,
                            }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis dataKey="value" />
                            <Tooltip />
                            <Bar dataKey="value" label="Antall avvik" fill="#00526b" />
                        </BarChart>
                    </ResponsiveContainer>
                </Paper>
                <br/>
                <DataGridPro pageSize={50} pagination={true} loading={isLoadingDeviationData} columns={
                    [
                        { headerName: "Id", field: "id", flex: true },

                        { headerName: "Prioritet", field: "deviationPriorityId", renderCell: rowData => (<div>{rowData.row.deviationPriority?.title}</div>) },
                        { headerName: "Posisjon|Komponent", field: "posistion", renderCell: rowData => (<div>{rowData.row.facilityPosition?.position} | {rowData.row.facilityPositionItem?.articleNr} - {rowData.row.facilityPositionItem?.articleDescription} </div>), flex: true },
                        { headerName: "Tittel", field: "title", flex: true },
                        { headerName: "Lokasjon navn", field: "location.name", renderCell: rowData => (<div>{rowData.row.location?.name}</div>), flex: true },
                        { headerName: "Ansvarlig", field: "responsibleUser.fullName", renderCell: rowData => (<div>{rowData.row.responsibleUser?.fullName}</div>), flex: true },
                        { headerName: "Registrert dato", field: "registeredDateTime", renderCell: rowData => (<Moment format="DD/MM/YYYY">{rowData.row.registeredDateTime}</Moment>), flex: true },
                        { headerName: "Lukket dato", field: "closedDateTime", renderCell: rowData => (<Moment format="DD/MM/YYYY">{rowData.row.closedDateTime}</Moment>), hide: (selectedPieSlice.data.keyValue === 1), flex: true },
                        { headerName: "Forfalls dato", field: "dueDateTime", renderCell: rowData => (<Moment format="DD/MM/YYYY">{rowData.row.dueDateTime}</Moment>), flex: true },
                    ]
                } rows={deviationData || []} rowHeight={34} headerHeight={56}  autoHeight={true} scrollbarSize={200} columnBuffer={2} sortingOrder={['asc', 'desc', null]} columnTypes={[]} density={"standard"} localeText={[]} />
            </>
            }
            {selectedPieSlice.type === 3 &&
            <>
                <Typography variant="h5" component="h2" style={{float: 'left'}}>
                    Fordeling
                </Typography>
                <ExcelFile element={<Button  style={{float: 'right'}}>Export to excel</Button>}>
                    <ExcelSheet data={resourcesByTypeData.map((item) => {
                        return {
                            ...item,
                            txtStatus: item.resourceStatus?.title,
                            txtResourceType: item.resourceType?.title,
                            txtLocation: item.location?.name
                        }
                    }) || []} name="Ressurser">
                        <ExcelColumn label="Id" value="id"/>
                        <ExcelColumn label="Type" value="txtResourceType"/>
                        <ExcelColumn label="Navn" value="name"/>
                        <ExcelColumn label="Produksjonsnummer" value="serialNumber"/>
                        <ExcelColumn label="Produsert" value="productionYear"/>
                        <ExcelColumn label="Status" value="txtStatus"/>
                        <ExcelColumn label="Omkrets" value="circumference"/>
                        <ExcelColumn label="Lokalitet" value="txtLocation"/>
                    </ExcelSheet>
                </ExcelFile>
                <div style={{clear: "both"}} />
                <br/>
                <DataGridPro pageSize={50} pagination={true} loading={isLoadingResourcesByType} columns={
                    [
                        { headerName: "Id", field: "id" },

                        { headerName: "Type", field: "ResourceTypeId", renderCell: rowData => (<div>{ rowData.row.resourceType?.title }</div>), flex: true },
                        { headerName: "Navn", field: "name" },
                        { headerName: "Produksjonsnummer", field: "serialNumber", flex: true },
                        { headerName: "Produsert", field: "productionYear", flex: true },
                        { headerName: "Status", field: "resourceStatusId", renderCell: rowData => (<div>{rowData.row.resourceStatus?.title}</div>), flex: true },
                        { headerName: "Omkrets", field: "circumference", flex: true},
                        { headerName: "Lokalitet", field: "locationId", renderCell: rowData => (<div>{rowData.row.location?.name}</div>), flex: true }
                    ]
                } rows={resourcesByTypeData || []} rowHeight={34} headerHeight={56}  autoHeight={true} scrollbarSize={200} columnBuffer={2} sortingOrder={['asc', 'desc', null]} columnTypes={[]} density={"standard"} localeText={[]} />
            </>
            }
            {selectedPieSlice.type === 4 &&
            <>
                <Typography variant="h5" component="h2" style={{float: 'left'}}>
                    Not sertifiseringer
                </Typography>
                <ExcelFile element={<Button  style={{float: 'right'}}>Export to excel</Button>}>
                    <ExcelSheet data={resourceNetsByCertificationData.map((item) => {
                        let a = moment(item.certifiedUntil);
                        let now = moment(new Date());
                        let days = a.diff(now, 'days');
                        
                        return {
                            ...item,
                            txtStatus: item.resourceStatus?.title,
                            txtCertDaysLeft: days,
                            txtLocation: item.location?.name
                        }
                    }) || []} name="Ressurser">
                        <ExcelColumn label="Id" value="id"/>
                        <ExcelColumn label="Navn" value="name"/>
                        <ExcelColumn label="Produksjonsnummer" value="serialNumber"/>
                        <ExcelColumn label="Produsert" value="productionYear"/>
                        <ExcelColumn label="Status" value="txtStatus"/>
                        <ExcelColumn label="Omkrets" value="circumference"/>
                        <ExcelColumn label="Sertifisert til" value="certifiedUntil"/>
                        <ExcelColumn label="Dager igjenn" value="txtCertDaysLeft"/>
                        <ExcelColumn label="Lokalitet" value="txtLocation"/>
                    </ExcelSheet>
                </ExcelFile>
                <div style={{clear: "both"}} />
                <br/>
                <DataGridPro pageSize={50} pagination={true} loading={isLoadingResourceNetsByCertification} columns={
                    [
                        { headerName: "Id", field: "id" },
                        
                        { headerName: "Navn", field: "name" },
                        
                        { headerName: "Produksjonsnummer", field: "serialNumber", flex: true },
                        { headerName: "Produsert", field: "productionYear", flex: true },
                        { headerName: "Status", field: "resourceStatusId", renderCell: rowData => (<div>{rowData.row.resourceStatus?.title}</div>), flex: true },
                        { headerName: "Omkrets", field: "circumference", flex: true },
                        
                        { headerName: "Sertifisert til", field: "certifiedUntil", renderCell: rowData => (<Moment format="DD/MM/YYYY">{rowData.row.certifiedUntil}</Moment>), flex: true },
                        { 
                            headerName: "Dager igjenn", 
                            field: "certifiedUntilDaysLeft", 
                            renderCell: rowData => (<Moment diff={new Date()} unit="days">{rowData.row.certifiedUntil}</Moment>), 
                            sortComparator: (v1, v2, cellParams1, cellParams2) => {
                                let a = moment(cellParams1.row.certifiedUntil);
                                let b = moment(cellParams1.row.certifiedUntil);
                                let now = moment(new Date());
                                return a.diff(now, 'days') > b.diff(now, 'days');
                            }
                            , flex: true },
                        
                        { headerName: "Lokalitet", field: "locationId", renderCell: rowData => (<div>{rowData.row.location?.name}</div>), flex: true }
                    ]
                } rows={resourceNetsByCertificationData || []} rowHeight={34} headerHeight={56}  autoHeight={true} scrollbarSize={200} columnBuffer={2} sortingOrder={['asc', 'desc', null]} columnTypes={[]} density={"standard"} localeText={[]} />
            </>
            }
        </div>
    )
}
export default MainComponents;