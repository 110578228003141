import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {Field, Form, Formik} from "formik";
import {TextField} from "formik-material-ui";
import FormControl from "@material-ui/core/FormControl";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import {withRouter} from "react-router-dom";
import LocalStorageService from "../Services/Storage/LocalStorageService";
import {useMutation} from "react-query";
import http from "../Services/Network/http";

const styles = theme => ({
    root: {
        height: '100vh',
        width: '100vw',
        backgroundColor: '#193b4d'
    },
    logoContainer: {
        marginTop: '30vh',
        marginBottom: 10,
        '& img': {
            width: 250,
        }
    },
    loginContainer: {
    },
    formContainer: {
        width: 300,
        padding: 10,
    },
    textField: {
        '& label': {
            fontWeight: 'bold',
            /*background: 'white',*/
        },
        marginTop: 8,
    },
});


function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {};
}

function Login(props) {
    const loginMutation = useMutation(loginCredential => http.post('/Authentication/AuthorizeWithCredentials', loginCredential), {
        onSuccess: (data, variables, context) => {
            LocalStorageService.setToken({access_token: data.token});
            props.history.push("/Dashboard");
        },
        onError: (error, variables, context) => {
            console.log(error.response.data);
        }, 
    });
    const { classes } = props;
    return (
        <div className={classes.root}>
            <Grid container justify="center" className={classes.logoContainer}>
                <Grid item>
                    <img src={'logo@2x.png'} alt="logo" />
                </Grid>
            </Grid>
            <Grid className={classes.loginContainer} container justify="center">
                <Grid item>
                    <Paper className={classes.formContainer}>
                        <Formik
                            initialValues={{
                                clientId: '',
                                username: '',
                                password: ''
                            }}
                            validationSchema={Yup.object({
                                clientId: Yup.number()
                                    .integer()
                                    .required('Påkrevd.'),
                                username: Yup.string()
                                    .required('Påkrevd.'),
                                password: Yup.string()
                                    .required('Påkrevd.'),
                            })}
                            onSubmit={(values, {setSubmitting}) => {
                                setSubmitting(false);
                                loginMutation.mutate({
                                    clientId: values.clientId, 
                                    username: values.username, 
                                    password: values.password
                                })
                                /*apiService.AuthenticationApi.apiAuthenticationAuthorizeWithCredentialsPost({clientId: values.clientId, username: values.username, password: values.password}).then((response) => {
                                    if(response && response.data) {
                                        LocalStorageService.setToken({access_token: response.data.token});
                                        props.history.push("/Dashboard");
                                    }
                                }).catch((error) => {
                                    console.log(error);
                                });*/
                            }}>
                            <Form className={classes.form}>
                                <FormControl className={classes.formControl} fullWidth={true}>
                                    <Field
                                        className={classes.textField}
                                        margin="normal"
                                        component={TextField}
                                        name="clientId"
                                        label="Kunde.Nr"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        autoFocus
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl} fullWidth={true}>
                                    <Field
                                        className={classes.textField}
                                        margin="normal"
                                        component={TextField}
                                        name="username"
                                        label="Brukernavn"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        autoComplete={'username'}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl} fullWidth={true}>
                                    <Field
                                        className={classes.textField}
                                        margin="normal"
                                        component={TextField}
                                        name="password"
                                        label="Passord"
                                        type="password"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        autoComplete={'current-password'}
                                    />
                                </FormControl>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}>
                                    Logg inn
                                </Button>
                                <Grid container>
                                    <Grid item xs>
                                        {/*<Link href="#" onClick={() => historyHelper.push('/Forgot')} variant="body2">
                                            Glemt passord?
                                        </Link>*/}
                                    </Grid>
                                    <Grid item>
                                        <Link href="#" onClick={() => props.history.push('/Registration')} variant="body2">
                                            {"Token?"}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Form>
                        </Formik>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(withStyles(styles)(withRouter(Login)));