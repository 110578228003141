import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import {pendingTasksReducer} from "react-redux-spinner";

export default function configureStore () {
    const reducers = {
    };

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
        enhancers.push(window.devToolsExtension());
    }

    const rootReducer = combineReducers({
        ...reducers,
        pendingTasks: pendingTasksReducer,
    });

    return createStore(
        rootReducer,
        compose(applyMiddleware(thunk), ...enhancers)
    );
}
